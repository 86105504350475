.bold {
    font-weight: bold;
}

.paddingtop-1 {
    padding-top: 1rem;
}

.paddingtop-2 {
    padding-top: 2rem;
}

.paddingtop-3 {
    padding-top: 3rem;
}

.paddingtop-4 {
    padding-top: 4rem;
}

.paddingtop-5 {
    padding-top: 5rem;
}

.paddingtop-6 {
    padding-top: 6rem;
}

.paddingtop-n1 {
    padding-top: -4rem;
}

/* mixin for multiline */
.multine-ellipsis {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.equal {
    height: 20rem;
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    left: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 15px;
}

.show-on-mob {
    display: none;
}

@media screen and (max-width: 992px) {
    .hide-on-mob {
        display: none;
    }

    .show-on-mob {
        text-align: center;
        font-weight: bold;
        display: block;
    }

    .ttm-right-header {
        padding-right: 1rem;
    }
}

.bar_content {
    display: block;

    width: 100%;
    transform: translateX(100%);

    animation: move 30s linear infinite;
}

/* Create the animation */
@keyframes move {
    to {
        transform: translateX(-100%);
    }
}

.truncate-1{
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}